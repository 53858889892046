// import {post} from "@/api";
// import Common from "@/config/Common";
// import VueJwtDecode from 'vue-jwt-decode'
// let token = localStorage.getItem('token');
// let dataLogin = null;
// if(token !== null){
//   //console.log("🚀 ~ file: _nav.js ~ line 6 ~ token", token)
//   dataLogin = VueJwtDecode.decode(token);
//   //console.log("🚀 ~ file: _nav.js ~ line 7 ~ dataLogin", dataLogin)
// }else{
//   dataLogin = 0;
// }
// import store from '@/store/index.js';

const superAdmin = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Status Doctor',
    to: '/status-doctor',
    icon: 'cil-group',
  },
  {
    component: 'CNavGroup',
    name: 'Approval',
    to: '/approval',
    icon: 'cil-pen-nib',
    items: [
      {
        component: 'CNavItem',
        name: 'Update Medical Record',
        to: '/approval/approval-update-medical-record',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Report',
    to: '/history',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Antar Obat',
        to: '/history/medicine-delivery',
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Online',
        to: '/history/online-schedule',
      },
      {
        component: 'CNavItem',
        name: 'Refund',
        to: '/history/refund',
      },
      {
        component: 'CNavItem',
        name: 'Report Pending',
        to: '/history/report_pending',
      },
      {
        component: 'CNavItem',
        name: 'Doctor Online',
        to: '/history/online-doctor',
      },
      {
        component: 'CNavItem',
        name: 'Doctor Offline',
        to: '/history/offline-doctor',
      },
      {
        component: 'CNavItem',
        name: 'Doctor Online Free',
        to: '/history/online-doctor-free',
      },
      {
        component: 'CNavItem',
        name: 'Homecare',
        to: '/history/homecare',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/history/payment',
      },
      {
        component: 'CNavItem',
        name: 'User',
        to: '/history/user',
      },
      {
        component: 'CNavItem',
        name: 'Penyakit',
        to: '/history/diagnose',
      },
      {
        component: 'CNavItem',
        name: 'Obat',
        to: '/history/medicine',
      },
      {
        component: 'CNavItem',
        name: 'Consultation List',
        to: '/consultation/list',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Clinic',
    to: '/clinic',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'Report Clinic',
        to: '/clinic/report',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Wellings',
    to: '/wellings',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'Wellings Code',
        to: '/wellings/wellingscode',
      },
      {
        component: 'CNavItem',
        name: 'Report Wellings',
        to: '/wellings/report',
      },
      {
        component: 'CNavItem',
        name: 'Report Wellings DOF',
        to: '/wellings_dof/report',
      },
    ],
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'Clinic',
  //   to: '/clinic',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Registrasi Pasien',
  //       to: '/clinic/registration'
  //     },
  //   ]
  // },
  {
    component: 'CNavGroup',
    name: 'Medical Record',
    to: '/medical-record',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Approval',
        to: '/approval-record',
      },
      {
        component: 'CNavItem',
        name: 'Input',
        to: '/input-record',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Medicines',
    to: '/medicines',
    icon: 'cil-medical-cross',
    items: [
      {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/medicines',
      },
      {
        component: 'CNavItem',
        name: 'Vendor',
        to: '/medicines/vendor',
      },
      {
        component: 'CNavItem',
        name: 'Create Order',
        to: '/medicines/order',
      },
      {
        component: 'CNavItem',
        name: 'On process',
        to: '/medicines/process',
      },
      {
        component: 'CNavItem',
        name: 'Finish',
        to: '/medicines/finish',
      },
      {
        component: 'CNavItem',
        name: 'Stock',
        to: '/medicines/stock',
      },
      {
        component: 'CNavItem',
        name: 'Log',
        to: '/medicines/log',
      },
      {
        component: 'CNavItem',
        name: 'Retur',
        to: '/medicines/retur',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Log',
    to: '/log',
    icon: 'cil-notes',
    items: [
      // {
      //   component: 'CNavItem',
      //   name: 'Payment',
      //   to: '/logs/payment',
      // },
      // {
      //   component: 'CNavItem',
      //   name: 'Delivery',
      //   to: '/logs/delivery',
      // },
      {
        component: 'CNavItem',
        name: 'Stock Obat',
        to: '/logs/stock-medicine',
      },
      {
        component: 'CNavItem',
        name: 'Harga Obat',
        to: '/logs/price-medicine',
      },
      {
        component: 'CNavItem',
        name: 'Asset',
        to: '/logs/asset',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Master',
    to: '/master',
    icon: 'cil-applications',
    items: [
      {
        component: 'CNavItem',
        name: 'Mitra',
        to: '/master/partner',
      },
      {
        component: 'CNavItem',
        name: 'User',
        to: '/master/user',
      },
      {
        component: 'CNavItem',
        name: 'Voucher',
        to: '/master/voucher',
      },
      {
        component: 'CNavItem',
        name: 'Poly',
        to: '/master/poly',
      },
      {
        component: 'CNavItem',
        name: 'Dokter',
        to: '/master/doctor',
      },
      {
        component: 'CNavItem',
        name: 'Penyakit',
        to: '/master/diseases',
      },
      {
        component: 'CNavItem',
        name: 'Tindakan',
        to: '/master/tindakan',
      },
      {
        component: 'CNavItem',
        name: 'Medicine',
        to: '/master/medicine',
      },
      {
        component: 'CNavItem',
        name: 'Admin',
        to: '/master/admin',
      },
      {
        component: 'CNavItem',
        name: 'Artikel',
        to: '/master/article',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Wellings Code',
      //   to: '/master/wellingscode'
      // },
      {
        component: 'CNavItem',
        name: 'Banner',
        to: '/master/banner',
      },
      {
        component: 'CNavItem',
        name: 'Terms and Conditions',
        to: '/master/tnc',
      },
      {
        component: 'CNavItem',
        name: 'Homecare Service',
        to: '/master/homecare_service',
      },
      {
        component: 'CNavItem',
        name: 'Room',
        to: '/master/room',
      },
      {
        component: 'CNavItem',
        name: 'Insurer',
        to: '/master/insurer',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Role',
      //   to: '/master/role',
      // },
    ],
  },
]

// const faskes = dataLogin.id_medical_facility == 0 ? superAdmin : userAdmin
// const a = store.getters.getMedfac == 0 ? superAdmin : userAdmin;
export default superAdmin
