import Common from '../config/Common'
import { useStore } from 'vuex'

const endPoint = (path) => process.env.VUE_APP_URL + path;
const registerEndpoint = (path) => process.env.VUE_REGISTER_URL + path;

const Services = {
    refresh_token: function(){
        return SDResolveGET(endPoint('admin/token'))
    },
    admin: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/admin-list'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/register'), params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/profile'), params)
        },
        change_password: function(params) {
            return SDResolvePOST(endPoint('admin/upass'), params)
        },
    },
    profile:  {
        get: function (params) {
            return SDResolveGET(endPoint('admin/info'))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/profile'), params)
        }
    },
    login: function (params){
        return SDResolvePOST(endPoint('admin/login'), params)
    },
    partner: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-mitra'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-mitra'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-mitra/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-mitra'), params)
        }
    },
    poly: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-poly'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-poly'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-poly/'+ params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-poly'), params)
        }
    },
    doctor: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-dokter'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-dokter'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-dokter/'+ params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-dokter'), params)
        },
        addExisting: function (params){
            return SDResolvePOST(endPoint('admin/dokter-existing'), params)
        },
        getLog: function(params){
            return SDResolvePOST(endPoint('admin/list_doctor_online_offline_log'), params)
        },
        getStatus: function(params){
            return SDResolvePOST(endPoint('admin/list-dokter-status'), params)
        },
        changePassword: function(params) {
            return SDResolvePOST(endPoint('admin/change-password'), params)
        }
    },
    icds: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-icds'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-icds'), params)
        },
        delete: function(params) {
            return SDResolvePOST(endPoint('admin/delete-icds'), params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-icds'), params)
        }
    },
    medicine: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-medicines'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-medicines'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-medicines/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-medicines'), params)
        },
        get_all: function (params){
            return SDResolvePOST(endPoint('admin/list-medicines-all'))
        }
    },
    medicine_dashboard: {
        get: function (params) {
            return SDResolveGET(endPoint('admin/medicine/dashboard'), params)
        },
    },
    medicine_vendor: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/medicine/pbfnc'), params)
        },
    },
    tnc: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-tnc'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-tnc'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-tnc/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-tnc'), params)
        }
    },
    medfac_poly: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-medical-facility-poly'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-medical-facility-poly'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-medical-facility-poly/'+ params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-medical-facility-poly'), params)
        }
    },
    medfac_doctor: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-medical-facility-poly-doctor'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-medical-facility-poly-doctor'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-medical-facility-poly-doctor/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-medical-facility-poly-doctor'), params)
        }
    },
    user: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-user'), params)
        },
        block_user: function (params) {
            return SDResolvePOST(endPoint('admin/block-user'), params)
        },
        unblock_user: function (params) {
            return SDResolvePOST(endPoint('admin/unblock-user'), params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-user'), params)
        }
    },
    family: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/family'), params)
        },
        update: function(params){
            return SDResolvePOST(endPoint('admin/update-family'), params)
        },
        delete: function(params){
            return SDResolvePOST(endPoint('admin/delete-family'), params)
        }
    },
    address: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-address'), params)
        },
        update: function(params){
            return SDResolvePOST(endPoint('admin/update-address'), params)
        },
        delete: function(params){
            return SDResolvePOST(endPoint('admin/delete-address'), params)
        }
    },
    article: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-article-news'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-article-news'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-article-news/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-article-news'), params)
        }
    },
    doctor_schedule: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-jadwal-dokter'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-jadwal-dokter'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-jadwal-dokter/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-jadwal-dokter'), params)
        }
    },
    doctor_online: {
        chat: function (params) {
            return SDResolvePOST(endPoint('admin/list-dokter-online-chat'), params)
        },
        diagnose: function(params) {
            return SDResolvePOST(endPoint('admin/create-dokter-online-diagnose'), params)
        },
        medicine: function(params) {
            return SDResolvePOST(endPoint('admin/delete-dokter-medicine'), params)
        },
        list: function (params) {
            return SDResolvePOST(endPoint('admin/list-dokter-online'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail-dokter-online'), params)
        },
        user: function (params) {
            return SDResolvePOST(endPoint('admin/list-do-user'), params)
        },
    },
    approval: {
        medical_record: function (params) {
            return SDResolvePOST(endPoint('admin/list_update_medical_record'), params)
        },
        update_medical_record: function (params) {
            return SDResolvePOST(endPoint('admin/update_medical_record'), params)
        },
    },
    doctor_online_free: {
        list: function (params) {
            return SDResolvePOST(endPoint('admin/list-dokter-online-free'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail-dokter-online-free'), params)
        },
    },
    medfac_medicine: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-medical-medicine'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-medical-medicine'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-medical-medicine/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-medical-medicine'), params)
        }
    },
    adminv2: {
        // get: function (params) {
        //     return SDResolvePOST(endPoint('admin/list-medical-medicine'), params)
        // },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-admin'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-admin/' + params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-admin'), params)
        }
    },
    medicine_additional: {
        edit_stock: function(params) {
            return SDResolvePOST(endPoint('admin/update-medical-medicine-stock'), params)
        },
        edit_price: function(params) {
            return SDResolvePOST(endPoint('admin/update-medical-medicine-price'), params)
        },
    },
    homecare: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-homecare'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail-homecare'), params)
        },
    },
    drugs_delivery: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-drug-delivery'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail-drug-delivery'), params)
        },
        update: function (params) {
            return SDResolvePOST(endPoint('admin/update-drug-delivery'), params)
        },
        prepare_drugs: function (params) {
            return SDResolvePOST(endPoint('admin/submitdriver'), params)
        }
    },
    payment: {
        get: function (params) {
            return  SDResolvePOST(endPoint('admin/list-payment'),  params)
        },
        getAll: function(params){
            return SDResolvePOST(endPoint('admin/list-payment-export'), params)
        }
    },
    homecare_service:{
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-homecare-service'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-homecare-service'), params)
        },
        delete: function(params) {
            return SDResolvePOST(endPoint('admin/delete-homecare-service'), params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-homecare-service'), params)
        }
    },
    report: {
        list_diagnose: function (params) {
            return SDResolvePOST(endPoint('admin/list-report-diagnose'), params)
        },
        list_do_diagnose: function (params) {
            return SDResolvePOST(endPoint('admin/list-do-report-diagnose'), params)
        },
        list_medicine: function (params) {
            return SDResolvePOST(endPoint('admin/list-report-medicine'), params)
        },
        list_do_medicine: function (params) {
            return SDResolvePOST(endPoint('admin/list-do-report-medicine'), params)
        },
    },
    banner: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-banner'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-banner'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-banner/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-banner'), params)
        }
    },
    wellings: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list-kode'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create-list-kode'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete-list-kode/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update-list-kode'), params)
        },
        //report
        total_consultation: function(params) {
            return SDResolvePOST(endPoint('admin/jumlah_konsul'), params)
        },
        detail: function(params) {
            return SDResolvePOST(endPoint('admin/detail_konsul'), params)
        },
        diagnosa: function(params) {
            return SDResolvePOST(endPoint('admin/list_diagnosa_konsul'), params)
        },
        obat: function(params) {
            return SDResolvePOST(endPoint('admin/list_obat_konsul'), params)
        },
    },
    wellings_dof: {
        //report
        total_consultation: function(params) {
            return SDResolvePOST(endPoint('admin/jumlah_konsul_dof'), params)
        },
        detail: function(params) {
            return SDResolvePOST(endPoint('admin/detail_konsul_dof'), params)
        },
        diagnosa: function(params) {
            return SDResolvePOST(endPoint('admin/list_diagnosa_konsul_dof'), params)
        },
        obat: function(params) {
            return SDResolvePOST(endPoint('admin/list_obat_konsul_dof'), params)
        },
    },
    report_clinic: {
        getTotal: function(params) {
            return SDResolvePOST(endPoint('admin/jumlah_konsul_doctor_offline'), params)
        },
        getConsultation: function(params) {
            return SDResolvePOST(endPoint('admin/detail_konsul_doctor_offline'), params)
        },
        getMedicines: function(params) {
            return SDResolvePOST(endPoint('admin/list_obat_konsul_doctor_offline'), params)
        },
        getDiagnoses: function(params) {
            return SDResolvePOST(endPoint('admin/list_diagnosa_konsul_doctor_offline'), params)
        },
    },
    log: {
        payment: function(params) {
            return SDResolvePOST(endPoint('admin/list-paymentlog'), params)
        },

        drugs_delivery: function(params) {
            return SDResolvePOST(endPoint('admin/list-drug-deliverylog'), params)
        },

        medicine_price: function(params) {
            return SDResolvePOST(endPoint('admin/list-medical-medicine-pricelog'), params)
        },

        medicine_stock: function(params) {
            return SDResolvePOST(endPoint('admin/list-medical-medicine-stocklog'), params)
        },

        asset: function(params) {
            return SDResolvePOST(endPoint('admin/list_asset_log'), params)
        },
    },
    dashboard: {
        total_pres: function(){
            return SDResolveGET(endPoint('admin/jumlah_resep'))
        },
        total_pres_doctor: function() {
            return SDResolveGET(endPoint('admin/jumlah_resep_dokter'))
        },
        total_pres_doctor_offline: function() {
            return SDResolveGET(endPoint('admin/jumlah_resep_dokter_offline'))
        },
        total_pres_apotik: function(){
            return SDResolveGET(endPoint('admin/total_resep'))
        },
        total_obat: function(){
            return SDResolveGET(endPoint('admin/total_obat'))
        },
        total_penyakit: function() {
            return SDResolveGET(endPoint('admin/total_penyakit'))
        },

    },
    register: {
        search: function(params) {
            return SDResolvePOST(registerEndpoint('v1/users/search'), params)
        },
        list_doctor: function(params){
            return SDResolveGET(registerEndpoint('v1/users/list_dokter'));
        }
    },
    medical_record: {
        list: function(params) {
            return SDResolvePOST(endPoint('admin/list-medical-record'), params)
        },
        detail: function(params) {
            return SDResolvePOST(endPoint('admin/detail-medical-record'), params)
        },
        list_approve: function(params) {
            return SDResolvePOST(endPoint('admin/list_medical_record'), params)
        },
        approve: function(params){
            return SDResolvePOST(endPoint('admin/change_status'), params)
        } ,
        input_rm(params){
            return SDResolvePOST(endPoint('admin/store_rm'), params)
        },
        list_input_medical_record(params){
            return SDResolvePOST(endPoint('admin/list_input_medical_record'), params)
        }
    },
    room: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_room'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_room'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_room/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_room'), params)
        },
    },
    asset: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_asset'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_asset'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_asset/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_asset'), params)
        },
    },
    doctor_offline: {
        list: function (params) {
            return SDResolvePOST(endPoint('admin/list-dokter-offline'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail-dokter-offline'), params)
        },
    },
    diseases: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_disease'), params)
        },
        detail: function (params) {
            return SDResolvePOST(endPoint('admin/detail_disease'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_disease'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_disease/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_disease'), params)
        },
    },
    diagnose: {
        get_all: function (params) {
            return SDResolvePOST(endPoint('admin/list_icds'), params)
        },
    },
    treatment: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_medical_treatment'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_medical_treatment'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_medical_treatment/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_medical_treatment'), params)
        },
    },
    medical_treatment: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_medical_facility_treatment'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_medical_facility_treatment'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_medical_facility_treatment/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_medical_facility_treatment'), params)
        },
    },
    pending: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_pending'), params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/cancel_pending'), params)
        },
    },
    voucher: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_voucher'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_voucher'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_voucher/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_voucher'), params)
        },
    },
    user_voucher: {
        get: function (params) {
            return SDResolvePOST(endPoint('admin/list_user_voucher'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/create_user_voucher'), params)
        },
        delete: function(params) {
            return SDResolveDELETE(endPoint('admin/delete_user_voucher/'+params))
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_user_voucher'), params)
        },
    },
    insurer: {
        get: function (params) {
            return SDResolveGET(endPoint('admin/list_insurer'), params)
        },
        create: function(params) {
            return SDResolvePOST(endPoint('admin/add_insurer'), params)
        },
        delete: function(params) {
            return SDResolvePOST(endPoint('admin/delete_insurer'),params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_insurer'), params)
        },
    },
    insurer_name: {
        create: function(params) {
            return SDResolvePOST(endPoint('admin/add_insurer_name'), params)
        },
        delete: function(params) {
            return SDResolvePOST(endPoint('admin/delete_insurer_name'),params)
        },
        update: function(params) {
            return SDResolvePOST(endPoint('admin/update_insurer_name'), params)
        },
    },
    medicinesOrder:{
        getMedicineList: function(params) {
            return SDResolveGET(endPoint('admin/medicine/list'), params)
        },
        submit: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/submit'), params)
        },
        getList: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order'), params)
        },
        finish: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/finish'), params)
        },
        cancel: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/delete'), params)
        },
        getDetail: function(params) {
            return SDResolveGET(endPoint('admin/medicine/order/'+params))
        },
        saveNote: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/save_notes'),params)
        },
        saveProcess: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/process'),params)
        },
        getProcess: function(params) {
            return SDResolveGET(endPoint('admin/medicine/order/process/'+params))
        },
        getMedicineStock: function(params) {
            return SDResolveGET(endPoint('admin/medicine/stock'), params)
        },
        getMedicineStockDetail: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/stock'), params)
        },
    },
    medicinesMenu:{
        getMedicineCategory: function(params) {
            return SDResolveGET(endPoint('admin/medicine/category'), params)
        },
        getMedicineData: function(params) {
            return SDResolveGET(endPoint('admin/medicine/dashboard'), params)
        },
        getVendor: function(params) {
            return SDResolveGET(endPoint('admin/medicine/pbf'), params)
        },
        getVendorDetail: function(params) {
            return SDResolveGET(endPoint('admin/medicine/pbf/'+params))
        },
        getVendorOrderDetail: function(params) {
            return SDResolveGET(endPoint('admin/medicine/pbf/detail/'+params))
        },
        savePrice: function(params) {
            return SDResolvePOST(endPoint('admin/medicine/order/save_price'),params)
        },
    }
}

export default Services;